<template>
  <b-card>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <div>
        <b-form-group>
          <b-button
            variant="outline-primary"
            @click="openModal"
          >
            Add Migration
          </b-button>
        </b-form-group>
      </div>
      <!-- <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group> -->
      <!-- Head Type -->
      <!-- <div class="d-flex col-4 mr-0 pr-0"> -->
      <b-form-group>
        <v-select
          v-model="blSyllabusLpMigrationClass"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Class"
          :options="migrationClassOptions"
          :reduce="val => val.id"
        />
      </b-form-group>
      <!-- </div> -->
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :line-numbers="true"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-primary"
              @click="openFeeConfigModal(props.row)"
            >Config</b-button>
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteStdFeeConfigParent(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <!-- Column: Term Sessions -->
        <span v-else-if="props.column.field === 'term_session'">
          {{ props.formattedRow[props.column.field].join(', ', ) }}
        </span>

        <!-- Column: Created By -->
        <span v-else-if="props.column.field === 'created_by'">
          {{ `${props.formattedRow[props.column.field].first_name} ${props.formattedRow[props.column.field].last_name}` }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Book List, Syallabus, Lesson Plan Add Modal -->
    <b-modal
      id="bl-syl-lp-migrate-modal"
      size="lg"
      title="Books, Syllabus & Lesson Plan Migrations"
      ok-title="Migrate"
      ok-variant="outline-primary"
      cancel-variant="outline-warning"
      @ok.prevent="saveMigration"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="10">
            <!-- Class -->
            <b-form-group
              label="Class"
            >
              <v-select
                v-model="formData.classId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="migrationClassOptions"
                placeholder="Select Class"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group>
              <v-select
                v-model="formData.fromSessionObj"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="From Session"
                :options="fromSessionOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group>
              <v-select
                v-model="formData.toSession"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="To Session"
                :options="toSessionOptions"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="5">
            <b-form-group>
              <v-select
                v-model="formData.fromTerm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="From Term"
                :options="fromTermOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group>
              <v-select
                v-model="formData.toTerm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="To Term"
                :options="toTermOptions"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col
            cols="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="addMigration"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <!-- Student Fee Config Child Grid -->
      <vue-good-table
        :columns="modalColumns"
        :rows="modalRows"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :line-numbers="true"
        theme="my-theme"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="removeTermListRow(props.row)"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </span>
          </span>

          <!-- Column: Head Type -->
          <span v-else-if="props.column.field === 'coa_info'">
            {{ props.formattedRow[props.column.field].gl_name }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BPagination, BForm, BFormGroup, BFormSelect, VBModal, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BForm,
    BFormGroup,
    BFormSelect,
    vSelect,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      modalEditMode: false,
      modalParentConfigId: null,
      formData: {
        fromSessionObj: null,
      },
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Class',
          field: 'academic_class.name',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'All Head Types',
          //   filterFn: this.headTypeFilterFn,
          //   filterDropdownItems: [
          //     { text: 'Debit Voucher (Debit Head)', value: '1' },
          //     { text: 'Debit Voucher (Credit Head)', value: '2' },
          //     { text: 'Journal Voucher (Debit Head)', value: '3' },
          //     { text: 'Journal Voucher (Credit Head)', value: '4' },
          //     { text: 'Advance Payment (Debit Head)', value: '5' },
          //     { text: 'Advance payment (Credit Head)', value: '6' },
          //     { text: 'Credit Voucher (Debit Head)', value: '7' },
          //     { text: 'Credit Voucher (Credit Head)', value: '8' },
          //   ],
          // },
        },
        {
          label: 'Term Session',
          field: 'term_session',
        },
        {
          label: 'Created By',
          field: 'created_by',
        },
        {
          label: 'Created At',
          field: 'created_at',
        },
        // {
        //   label: 'Action',
        //   field: 'action',
        // },
      ],
      rows: [],
      modalColumns: [
        {
          label: 'From Term',
          field: 'fromTermName',
        },
        {
          label: 'To Term',
          field: 'toTermName',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      modalRows: [],
      // Default Values
      blSyllabusLpMigrationClass: 1,
      stdFeeConfigSessionFilter: 29,

      // Default datasources
      fromSessionOptions: [],
      toSessionOptions: [],
      fromTermOptions: [],
      toTermOptions: [],
      migrationClassOptions: [],
      stdFeeConfigSessionOptions: [
      ],
      stdFeeCategoryOptions: [],
      stdFeeNameOptions: [],
      stdFeeConfigBranchOptions: [],
      stdFeeConfigPaymentCycleOptions: [
        { text: 'Installment 1', value: 1 },
        { text: 'Installment 2', value: 2 },
        { text: 'Installment 3', value: 3 },
        { text: 'Installment 4', value: 4 },
        { text: 'Installment 5', value: 5 },
        { text: 'Installment 6', value: 6 },
      ],
      stdFeeConfigAffectOnOptions: [
        { text: 'Monthly', value: 1 },
        { text: 'Specific', value: 2 },
      ],
      stdFeeConfigApplicableOnOptions: [
        { text: 'All Students', value: 1 },
        { text: 'New Students', value: 2 },
        { text: 'Existing Students', value: 3 },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    // fromSessionData() {
    //   return this.formData.fromSession
    // },
  },
  watch: {
    blSyllabusLpMigrationClass(newVal, oldVal) {
      if (newVal != null && newVal !== oldVal) {
        this.getMigrationHistory()
      }
    },
    // eslint-disable-next-line func-names
    'formData.fromSessionObj': function (newVal, oldVal) {
      // to work with changes in prop
      if (newVal !== oldVal) {
        this.formData.fromSession = newVal.id
        this.getToSessions(newVal.parent_sorting)
        this.getFromTerms()
      }
    },
    // eslint-disable-next-line func-names
    'formData.toSession': function (newVal, oldVal) {
      // to work with changes in prop
      if (newVal !== oldVal) {
        this.getToTerms()
      }
    },
  },
  created() {
    this.getClasses()
    this.getMigrationHistory()
    this.getFromSessions()
  },
  mounted() {
    // this.$root.$on('bv::modal::hide', () => {
    //   this.stdFeeCategoryFilter = null
    // })

    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (bvEvent.type === 'show' && modalId === 'std-fee-category-add-modal') this.resetForm()
    })
  },
  methods: {
    getClasses() {
      this.$http.get(`${window.apiUrl}academic/migration/classes`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.migrationClassOptions = res.data.data
          }
        })
    },
    getMigrationHistory() {
      this.$http.get(`${window.apiUrl}academic/migration/book-syllabus-lp/history/${this.blSyllabusLpMigrationClass}`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.rows = res.data.data
          }
        })
    },
    getFromSessions() {
      this.$http.get(`${window.apiUrl}academic/migration/from-sessions/${this.blSyllabusLpMigrationClass}`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.fromSessionOptions = res.data.data
          }
        })
    },
    getToSessions(parentSorting) {
      this.$http.get(`${window.apiUrl}academic/migration/to-sessions/${this.blSyllabusLpMigrationClass}/${parentSorting}`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.toSessionOptions = res.data.data
          }
        })
    },
    getFromTerms() {
      this.$http.get(`${window.apiUrl}academic/migration/terms/${this.blSyllabusLpMigrationClass}/${this.formData.fromSessionObj.id}`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.fromTermOptions = res.data.data
          }
        })
    },
    getToTerms() {
      this.$http.get(`${window.apiUrl}academic/migration/terms/${this.blSyllabusLpMigrationClass}/${this.formData.toSession}`)
        .then(res => {
          if (res.data.status) {
            // this.stdFeeConfigClassVal = 1
            this.toTermOptions = res.data.data
          }
        })
    },
    addMigration() {
      console.log(this.formData)
      if (!this.formData.fromTerm || !this.formData.toTerm) {
        FemsToastrService.error('Please select both From Term and To Term')
        return
      }
      this.modalRows.push({
        fromTermId: this.formData.fromTerm.id,
        fromTermName: this.formData.fromTerm.name,
        fromTermSorting: this.formData.fromTerm.sorting,
        toTermId: this.formData.toTerm.id,
        toTermName: this.formData.toTerm.name,
        toTermSorting: this.formData.toTerm.sorting,
      })
      this.fromTermOptions = this.fromTermOptions.filter(item => item.id !== this.formData.fromTerm.id)
      this.toTermOptions = this.toTermOptions.filter(item => item.id !== this.formData.toTerm.id)
      this.formData.fromTerm = null
      this.formData.toTerm = null
    },
    removeTermListRow(rowData) {
      console.log(rowData)
      this.fromTermOptions.push({
        id: rowData.fromTermId,
        name: rowData.fromTermName,
        sorting: rowData.fromTermSorting,
      })
      this.toTermOptions.push({
        id: rowData.toTermId,
        name: rowData.toTermName,
        sorting: rowData.toTermSorting,
      })
      this.modalRows.splice(rowData.originalIndex, 1)
    },
    openModal() {
      this.$bvModal.show('bl-syl-lp-migrate-modal')
    },
    saveMigration() {
      const params = {
        classId: this.formData.classId,
        fromSecSessionId: this.formData.fromSession,
        toSecSessionId: this.formData.toSession,
        terms: this.modalRows.map(item => ({ from: item.fromTermId, to: item.toTermId })),
        ipAddress: '10.10.100.5',
      }

      if (!this.formData.classId || !this.formData.fromSession || !this.formData.toSession || params.terms.length < 1) {
        FemsToastrService.error('Please fill out all fields')
        return
      }
      this.$nextTick(() => {
        this.$bvModal.hide('bl-syl-lp-migrate-modal')
      })
      params.terms = JSON.stringify(params.terms)
      this.$http.post(`${window.apiUrl}academic/migrate-book-syllabus-lesson-plan`, params)
        .then(res => {
          if (res.data.status) {
            FemsToastrService.success(res?.data.message)
          } else {
            FemsToastrService.error(res?.data.message)
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    resetForm() {
      this.stdFeeCategoryFilter = null
      this.stdFeeNameFilter = null
    },
  },
}
</script>
